//		Sidebar
// ==|====================

.left-sidebar {
	// display: block;
	position: absolute;
	width: 240px;
	height: 100%;
	padding-top: 70px;
	top: 0px;
	z-index: 20;
	background: #003544;
}

.sidebar-nav {
	padding: 12px 0 0 0px;
	background: #003544;

	ul {
		padding: 0;
		margin: 0;

		li {
			// Active lien
			&.active a {
				background: #95c11f;
				// Hover
				@include hover {
					text-decoration: none;
					background: #95c11f;
				}
			}

			a {
				display: block;
				padding: 8px 35px 8px 15px;
				font-size: 14px;
				font-weight: 400;
				color: #fff;

				// Hover
				@include hover {
					text-decoration: none;
					background: #95c11f;
				}

				// Icone
				i {
					display: inline-block;
					width: 35px;
					vertical-align: middle;
					font-size: 20px;
					color: #fff;

					// First Child
					&:first-child {
						margin-left: -6px;
					}
				}
			}
		}
	}

	li.copyright {
		padding: 16px 10px;
		margin-top: 70px;
		font-size: 13px;
		text-align: center;
		color: white;
	}

	.copyright-txt {
		display: block;
		margin-top: 16px;
		opacity: .5;
	}
}

// RESPONSIVE
.left-sidebar {
	@include media-breakpoint-down(s) {
		left: -240px;
	}
}
