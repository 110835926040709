//		Docs
// ==|====================

.docs-wrapper {
	max-width: 1140px;
	margin: 0 auto;
}

// Grid exemple
.docs-example-row {
	.row + .row {
		margin-top: 1rem;
	}

	.row {
		> .col,
		> [class^="col-"] {
			padding-top: .75rem;
			padding-bottom: .75rem;
			background-color: rgba(86, 61, 124, .15);
			border: 1px solid rgba(86, 61, 124, .2);
		}
	}

	.flex-items-top,
	.flex-items-middle,
	.flex-items-bottom {
		min-height: 6rem;
		background-color: rgba(255, 0, 0, .1);
	}
}

// Docs examples
.docs-example {
	position: relative;
	padding: 1rem;
	margin: 1rem (-$grid-gutter-width / 2);
	border: solid #f7f7f9;
	border-width: .2rem 0 0;

	@include clearfix();

	@include media-breakpoint-up(sm) {
		padding: 1.5rem;
		margin-right: 0;
		margin-bottom: 1.5rem;
		margin-left: 0;
		border-width: .2rem;
	}

	+ .highlight,
	+ .clipboard + .highlight {
		margin-top: 0;
	}

	+ p {
		margin-top: 2rem;
	}

	.pos-f-t {
		position: relative;
		margin: -1rem;

		@include media-breakpoint-up(sm) {
			margin: -1.5rem;
		}
	}

	> .form-control {
		+ .form-control {
			margin-top: .5rem;
		}
	}

	> .nav + .nav,
	> .alert + .alert,
	> .navbar + .navbar,
	> .progress + .progress,
	> .progress + .btn {
		margin-top: 1rem;
	}

	> .dropdown-menu:first-child {
		position: static;
		display: block;
	}

	> .form-group:last-child {
		margin-bottom: 0;
	}
}

.p-3 {
	padding: 1rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

@each $color, $value in $theme-colors {
	.swatch-#{$color} {
		background-color: #{$value};

		@include color-pi($value);
	}
}
