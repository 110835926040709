//		UI Components
// ==|====================

// Cards
.card {
	border: 0px;
	border-radius: 0px;
	box-shadow: none;
}
// FLASH INFO
// Flash Info Bar
.flash-info {
	position: relative;
	padding: 7px 0;
	// height: 35px;
	z-index: 50;
	// line-height: 35px;
	text-align: center;
	background: #dc3545;
}

// Body flash info class !important
.fi {
	// Sidebar
	.left-sidebar {
		padding-top: 105px;
	}

	// Responsive
	@include media-breakpoint-down (m) {
		// Sidebar
		.left-sidebar {
			padding-top: 105px;
		}

		&.show-sidebar .left-sidebar {
			padding-top: 145px;
		}
		// Content
		.page-content {
			// padding-top: 130px;
		}
	}

	@media (max-width: 575px) {
		&.show-sidebar .left-sidebar {
			padding-top: 165px;
		}
	}
}

// Button
.btn {
	cursor: pointer;
}

// Langages
.languages {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 16px 0;

	// Images
	img {
		margin-right: 16px;
	}
}
