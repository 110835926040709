//		Header
// ==|====================

// Top Bar header
.topbar {
	position: relative;
	z-index: 50;
	font-size: rem(14);
	background: #95c11f;

	// Navbar
	.top-navbar {
		padding: 0px;
		min-height: 70px;
	}

	// Navbar Collapse
	.navbar-collapse {
		padding: 0 10px;
	}
}

// Navbar Header
.navbar-header {
	// padding-left: 10px;
	line-height: 55px;
	// background: #272c33;
	background: #000;
	// Navbar brand
	.navbar-brand {
		display: inline-block;
		width: 240px;
		margin: 0;
		padding: 0;
		line-height: 70px;
		text-align: center;
	}

	// Responsive
	// @media (min-width: 768px)
	@include media-breakpoint-up(m) {
		width: 240px;
		flex-shrink: 0;
	}
}

// Navbar Menu
.navbar-menu {
	// Button recherche
	.button-search {
		padding: 5px 3px 0;
		margin-right: 10px;
		background-color: transparent;

		// icone recherche
		i {
			color: #fff;

			@include hover {
				color: #101010;
			}
		}
	}

	// Input recherche
	.input-search {
		max-width: 170px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-radius: 30px;
		color: white;
		font-size: 14px;
		border: 1px solid #aacd4c;
		background: #aacd4c;

		// Placeholdee
		&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color: #fff !important;
		}

		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: #fff !important;
			opacity: 1;
		}

		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: #fff !important;
			opacity: 1;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #fff !important;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			color: #fff !important;
		}

		// Focused
		&:focus {
			box-shadow: none;
		}
	}

	// Liens
	a.nav-link {
		padding-left: 15px;
		padding-right: 15px;
		color: #fff;

		@include hover {
			color: #101010;
		}
	}

	// Icons
	i {
		display: block;
		margin-top: 2px;
		margin-bottom: 2px;
		font-size: 25px;
		text-align: center;
	}

	// Utilisateur
	.item-account {
		display: flex;
		flex-flow: row;
		padding: 0;
	}

	.account-img {
		position: relative;
		height: 50px;
		width: 50px;
		padding: 0;
		margin: 6px 12px;
		cursor: pointer;
		border-radius: 100%;
		background-color: #aacd4c;

		// Icon
		i {
			position: absolute;
			padding: 0;
			margin: 0;
			top: 0px;
			left: 2px;
			border-radius: 50%;
			font-size: 45px;
			color: #fff;

			@include hover {
				color: #101010;
			}
		}
	}

	.account-name {
		display: inline-block;
		cursor: pointer;
		line-height: 70px;
	}
}

// Icon menu
// Icon menu
.nav-toggler { display: none; }

.nav-toggler {
	@include hover {
		color: #fff !important;
	}

	i.icon-menu {
		margin-top: -20px;
		font-size: 50px;
	}

	@include media-breakpoint-down(s) {
		display: block;
	}
}

.navbar-nav {
	.icon-menu {
		line-height: 38px;
	}
}

// RESPONSIVE
.topbar {
	// Désactivé pour le moment pour une version supérieur
	// @include media-breakpoint-down(s) {
	// 	position: fixed;
	// 	width: 100%;
	// }

	@include media-breakpoint-down(s) {
		.top-navbar {
			flex-direction: row;
			/* flex-wrap: nowrap; */
			flex-wrap: wrap;
			width: 100%;
			min-height: 60px;

			// Navbar Header
			.navbar-header {
				width: 100%;
				padding-top: 5px;
				line-height: 35px;

				.navbar-brand {
					width: 100%;
					line-height: 0;
				}
			}

			// Navbar collapse
			.navbar-collapse {
				display: flex;
				width: 100%;
				min-height: 60px;
			}

			// Search
			.search-box {
				display: none;
			}

			// Navbar Nav
			.navbar-nav {
				flex-direction: row;

				span.hide-span {
					display: none;
				}
			}
		}
	}

	@include media-breakpoint-down(m) {
		.navbar-menu {
			a.nav-link {
				// padding-left: 4px;
				// padding-right: 4px;
			}

			.input-search {
				width: 100px;
			}

			.account-img {
				margin: 0;
				margin: 6px 12px;
			}
		}
	}
}

.show-sidebar {
	.left-sidebar {
		left: 0px;
		padding-top: 100px;
	}
}
