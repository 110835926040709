//		Grid Framework
// ==|====================

/// Système de grille
// Framework de génération de grille
@mixin grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	// Propriétés communes pour tous les points d'arrêt
	%grid-column {
		position: relative;
		width: 100%;
		min-height: 1px;
		padding-right: ($gutter / 2);
		padding-left: ($gutter / 2);
	}

	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		// 
		@for $i from 1 through $columns {
			.col#{$infix}-#{$i} {
				@extend %grid-column;
			}
		}

		.col#{$infix},
		.col#{$infix}-auto {
			@extend %grid-column;
		}

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			// Provide basic `.col-{bp}` classes for equal-width flexbox columns
			.col#{$infix} {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
			}

			.col#{$infix}-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: none; // Reset earlier grid tiers
			}

			@for $i from 1 through $columns {
				.col#{$infix}-#{$i} {
					@include col($i, $columns);
				}
			}
		}
	}
}

///	 MIXINS
//==|====================
// Générer des colonnes de la grille sémantique avec ces mixins.
@mixin container() {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: ($grid-gutter-width / 2);
	padding-left: ($grid-gutter-width / 2);
}

// Pour chaque point d'arrêt, définit la largeur maximale du conteneur dans une requête de médias
@mixin container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

@mixin row() {
	display: flex;
	flex-wrap: wrap;
	margin-right: ($grid-gutter-width / -2);
	margin-left: ($grid-gutter-width / -2);
}

@mixin col($size, $columns: $grid-columns) {
	flex: 0 0 percentage($size / $columns);
	max-width: percentage($size / $columns);
}
