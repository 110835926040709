//		Mixins
// ==|====================

//* HOVER
@mixin hover {
	&:hover { @content; }
}
//__ Hovers & Focus
@mixin hover-focus {
	&:focus,
	&:hover { @content; }
}
//__ Hovers, Focus, Active
@mixin hover-focus-active {
	&:focus,
	&:active,
	&:hover { @content; }
}
//__ Hovers, Focus, *
@mixin plain-hover-focus {
	&,
	&:focus,
	&:hover { @content; }
}
//__ Focus
@mixin focus {
	&:focus { @content; }
}

//__ Box Shadow
@mixin box-shadow($shadow...) {
	box-shadow: $shadow;
}

// Transition
@mixin transition($transition...) {
	@if length($transition) == 0 {
		transition: $transition-base;
	}

	@else {
		transition: $transition;
	}
}

// Clearfix
@mixin clearfix() {
	&::after {
		display: block;
		clear: both;
		content: "";
	}
}

// Border Radius
@mixin border-radius($radius: $border-radius) {
	border-radius: $radius;
}

// Couleurs
@mixin color-pi($color) {
	$r: red($color);
	$g: green($color);
	$b: blue($color);

	$pi: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

	@if ($pi >= 150) {
		color: #111;
	}

	@else {
		color: #fff;
	}
}

// BUTTON
// Button variants
//
@mixin button-variant($background, $border, $active-background: darken($background, 7.5%), $active-border: darken($border, 10%)) {
	@include color-pi($background);

	background-color: $background;
	border-color: $border;

	// @include box-shadow($button-box-shadow);

	@include hover {
		@include color-pi($active-background);

		background-color: $active-background;
		border-color: $active-border;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 3px rgba($border, .5);
	}

	&:active,
	&.active {
		background-color: $active-background;
		background-image: none;
		border-color: $active-border;

		@include box-shadow($button-active-box-shadow);
	}
}
// Button outline variants
@mixin button-outline-variant($color, $color-hover: #fff) {
	border-color: $color;
	color: $color;
	background-color: transparent;
	background-image: none;

	@include hover {
		border-color: $color;
		color: $color-hover;
		background-color: $color;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 3px rgba($color, .5);
	}

	&:active,
	&.active {
		color: $color-hover;
		background-color: $color;
		border-color: $color;
	}
}

// Mixins Grid
//** ==|====================
@import "mixins/breakpoints";
@import "mixins/grid-framework";
