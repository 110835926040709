//		Content
// ==|====================

// .main-wrapper {
// 	width: 100%;
// 	overflow: hidden;
// }

* {
	outline: none;
}

html body .m-r-10 {
	margin-right: 10px;
}

.page-content {
	min-height: 100%;

	// Container
	.container-fluid {
		// padding: 25px;
		// padding-top: 25px;
		// padding-bottom: 25px;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	// Responsive
	@include media-breakpoint-up (m) {
		margin-left: 240px;
	}
}

// Breadcrumb
.breadcrumb {
	padding: 0;
	background: transparent;

	a {
		color: #66868f;
	}
}

.breadcrumb-item + .breadcrumb-item::before {
	content: ">";
}

.list-text {
	margin-left: 18px;
}

// Tableau dashboard
.nopadding-table {
	background: transparent;

	.card-boy {
		padding: 0;
	}
}

.decsdash {
	margin-bottom: 16px;
}

// RESPONSIVE
.page-content {
	@include media-breakpoint-down(s) {
		margin-left: 0px;
		// padding-top: 100px;
	}
}
