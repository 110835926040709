//		Base
// ==|====================

// Body & Html
//
html {
	position: relative;
	min-height: 100%;
}

body {
	font-family: 'Roboto', $font-family-base;
	font-size: rem($font-size-base);
	font-weight: $font-weight-base;
	line-height: $line-height-base;
	color: $body-color;
	background-color: $body-bg;
	// background: #f4f6f9;
}

// Liens
a {
	color: #003544;

	@include hover {
		color: #003544;
		text-decoration: none;
	}
}

// FORMULAIRES
// Placeholder
// ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
// 	color: #fff !important;
// }

// :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
// 	color: #fff !important;
// 	opacity: 1;
// }

// ::-moz-placeholder { /* Mozilla Firefox 19+ */
// 	color: #fff !important;
// 	opacity: 1;
// }

// :-ms-input-placeholder { /* Internet Explorer 10-11 */
// 	color: #fff !important;
// }

// ::-ms-input-placeholder { /* Microsoft Edge */
// 	color: #fff !important;
// }
