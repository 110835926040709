/*
 NE MODIFIEZ PAS DIRECTEMENT!
 Généré automatiquement par iconFont.js de gulpfile/tasks/iconFont/template.scss
*/

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format('embedded-opentype'),
		url("../fonts/icons.woff") format('woff'),
		url("../fonts/icons.ttf") format('truetype'),
		url("../fonts/icons.svg#icons") format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
	display: inline-block;
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}

.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }

.icon-fw {
	width: 1.28571429em;
	text-align: center;
}

.icon-Editer:before {
	content: "\EA01";
}

.icon-alarmes:before {
	content: "\EA02";
}

.icon-deconnexion:before {
	content: "\EA03";
}

.icon-enregistrer:before {
	content: "\EA04";
}

.icon-faq:before {
	content: "\EA05";
}

.icon-localiser:before {
	content: "\EA06";
}

.icon-logo-novea_connect:before {
	content: "\EA07";
}

.icon-logo-novea_energies:before {
	content: "\EA08";
}

.icon-menu:before {
	content: "\EA09";
}

.icon-passerelle:before {
	content: "\EA0A";
}

.icon-rechercher:before {
	content: "\EA0B";
}

.icon-tableau_de_bords:before {
	content: "\EA0C";
}

.icon-utilisateur:before {
	content: "\EA0D";
}


