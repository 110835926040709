//		Variables
// ==|====================

//= Couleur
// Couleur défaut
$white:  #fff !default;
$black:  #000 !default;

// Palette de ouleur
$gray-1: #f8f9fa !default;
$gray-2: #343a40 !default;
$gray-3: #868e96 !default;
$gray-4: #343a40 !default;
$blue:   #007bff !default;
$green:  #28a745 !default;
$cyan:   #17a2b8 !default;
$yellow: #ffc107 !default;
$red:    #dc3545 !default;

$dark:			$gray-4 !default;
$light: 		$gray-1 !default;
$primary:		$blue !default;
$secondary: $gray-3 !default;
$success: 	$green !default;
$info: 			$cyan !default;
$warning: 	$yellow !default;
$danger:		$red !default;

// Theme color palette classes
$theme-colors: () !default;
$theme-colors: map-merge(
(
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
	dark: $dark ),
	$theme-colors
);

// Body
//
$body-bg: $white !default;
$body-color: $gray-2 !default;

// Liens
//

// Paragraphes
//

// Grille
//== Points d’arrêt de la grille
$grid-breakpoints: (
s: 0,
m: 768px,
l: 1024px) !default;

// Conteneurs grille
// Définir la largeur maximale de `.container` pour différentes tailles d’écran.
$container-max-widths: (
m: 720px,
l: 1140px) !default;

// Colonnes de la grille
// Définir le nombre de colonnes et de spécifier la largeur des gouttières.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Composants
// 
$border-width: 1px !default;
$border-radius: 3 !default;// (4px)

$transition-base: all .2s ease-in-out !default;

// Typographie
// Police, ligne-taille et couleur pour le corps du texte, titres et plus encore.
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base:$font-family-sans-serif !default;

$font-size-base: 16 !default; // Valeur par défaut du navigateur `16px`
$font-weight-normal: normal !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

// Buttons
//
$button-font-weight: $font-weight-normal !default;
// $button-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$button-transition: all .15s ease-in-out !default;

$button-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$button-focus-box-shadow: 0 0 0 3px rgba(theme-color("primary"), .25) !default;
$button-active-box-shadow: inset 0 3px 5px rgba($black, .125) !default;

// Z-index liste de base
//

