@charset 'UTF-8';

@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
// UTILS CORE - Configuration et aides
@import
	'abstracts/functions',
	'abstracts/variables',
	'abstracts/mixins';

// VENDORS - Vendors et les feuilles de style externes
// 
@import
	// '../vendors/bootstrap/bootstrap';
	'../vendors/bootstrap-4.0.0-beta.2/css/bootstrap';

// BASE
// 
@import
	'base/osreset',
	'base/base';

// CORE - CSS
// 
@import
	'core/grid',
	'core/buttons';

// COMPONENTS - Composants
// 
@import
	'icons/icons',
	'components/_ui-components';

// LAYOUT - Sections relatives à la disposition
// 
@import
	'layout/header',
	'layout/sidebar',
	'layout/content',
	'layout/footer';

// PAGES - Styles de page spécifique
// 

// Utilitaire - Classes utilitaires
// 
@import
	'utilities/flex',
	'utilities/utilities';

// THEMES - Thèmes
// 

/* DOCS */
@import 'docs/docs';
