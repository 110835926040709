//		Bouton
// ==|====================

.button {
	display: inline-block;
	font-weight: $button-font-weight;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	cursor: pointer;
	border: $border-width solid transparent;
	padding: rem(8) rem(12);
	font-size: rem($font-size-base);
	line-height: $line-height-base;

	@include border-radius(rem($border-radius));
	@include transition($button-transition);

	// Share hover and focus styles
	@include hover-focus {
		text-decoration: none;
	}

	&:focus,
	&.focus {
		outline: 0;
		box-shadow: $button-focus-box-shadow;
	}

	&:active,
	&.active {
		background-image: none;

		@include box-shadow($button-focus-box-shadow, $button-active-box-shadow);
	}
}

@each $color, $value in $theme-colors {
	.button-#{$color} {
		@include button-variant($value, $value);
	}
}

// Outline
@each $color, $value in $theme-colors {
	.button-outline-#{$color} {
		@if $color == "light" {
			@include button-outline-variant($value, $gray-4);
		}

		@else {
			@include button-outline-variant($value, $white);
		}
	}
}
